import React from 'react'
import { Link } from "gatsby";

import PartnerImg1 from '../../assets/images/partner-img/partner-1.png'
import PartnerHoverImg1 from '../../assets/images/partner-img/partner-hover1.png'

import PartnerImg3 from '../../assets/images/partner-img/partner-3.png'
import PartnerHoverImg3 from '../../assets/images/partner-img/partner-hover3.png'

import PartnerImg4 from '../../assets/images/partner-img/partner-4.png'
import PartnerHoverImg4 from '../../assets/images/partner-img/partner-hover4.png'


import PartnerImg5 from '../../assets/images/partner-img/partner-5.png'
import PartnerHoverImg5 from '../../assets/images/partner-img/partner-hover5.png'

import PartnerImg6 from '../../assets/images/partner-img/partner-6.png'
import PartnerHoverImg6 from '../../assets/images/partner-img/partner-hover6.png'

import PartnerImg7 from '../../assets/images/partner-img/partner-7.png'
import PartnerHoverImg7 from '../../assets/images/partner-img/partner-hover7.png'


import PartnerImg8 from '../../assets/images/partner-img/partner-8.png'
import PartnerHoverImg8 from '../../assets/images/partner-img/partner-hover8.png'


const Partner = () => {
    return (
        <>
            <div className="ready-to-talk">
                <div className="container">
                    <h3>Görüşmeye Ne Dersin? </h3>
                    <p>Ekibimiz size destek vermek için hazır</p>
                    
                    <Link to="/contact" className="btn btn-primary">
                        İLETİŞİM KUR
                    </Link>

                    <span>
                        <a aria-label="talep" href="/proje-talebi" rel="noreferrer">Veya , proje talebi başlat</a>
                    </span>
                </div>
            </div>

            <div className="partner-area partner-section">
                <div className="container">
                    <h5>Bizi tanıyan veya uygulamalarımızı kullanan 100binden fazla kişi arasına sende katıl</h5>

                    <div className="partner-inner">
                        <div className="row justify-content-center">
                            <div className="col-lg-2 col-md-3 col-6">
                                <a aria-label="akademi" href="https://akademihastaneleri.com/" target="_balnk" rel="noreferrer">
                                    <img src={PartnerImg1} alt="akademi hastaneleri mobil ranvu uygulaması" />
                                    <img src={PartnerHoverImg1} alt="online görüşme uygulaması" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a aria-label="vaden" href="https://vaden.com.tr/" target="_balnk" rel="noreferrer">
                                    <img src={PartnerImg3} alt="mobil katolog uygulamaları" />
                                    <img src={PartnerHoverImg3} alt="stok uygulamaları farklı dillerde mobil yazılımlar" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a aria-label="beysu" href="https://beysu.com.tr/" target="_balnk" rel="noreferrer">
                                    <img src={PartnerImg4} alt="beysu mobil su sipariş" />
                                    <img src={PartnerHoverImg4} alt="su sipariş banka entegrasyon uygulamaları" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a aria-label="daxler" href="https://www.daxlerenergy.com/" target="_balnk" rel="noreferrer">
                                    <img src={PartnerImg5} alt="daxler güneş enerjisi üretim akış" />
                                    <img src={PartnerHoverImg5} alt="parmak izi yüz yanıma pdks uygulamları" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a  aria-label="makçelik" href="https://makcelik.com.tr" target="_balnk" rel="noreferrer">
                                    <img src={PartnerImg6} alt="turnike geçiş üretim akış yazılımları" />
                                    <img src={PartnerHoverImg6} alt="çelik konstrüksiyon yazılımları" />
                                </a>
                            </div>


                            <div className="col-lg-2 col-md-3 col-6">
                                <a aria-label="icecekdukkani" href="https://www.icecekdukkani.com.tr/" target="_balnk" rel="noreferrer">
                                    <img src={PartnerImg7} alt="içecek sipariş uygulamaları" />
                                    <img src={PartnerHoverImg7} alt="yazılım partner" />
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-6">
                                <a aria-label="arc" href="https://arctechnologies.ca/" target="_balnk" rel="noreferrer">
                                    <img src={PartnerImg8} alt="ihrahat uygulamaları" />
                                    <img src={PartnerHoverImg8} alt="görüntü işleme yazılımları" />
                                </a>
                            </div>

                           
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
 
}

export default Partner;  